// Angular
import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DragDropModule} from '@angular/cdk/drag-drop';
// Translate Module
import {TranslateModule} from '@ngx-translate/core';
// NGRX
// UI
import {PartialsModule} from '../../../partials/partials.module';
// Core
// Auth
import {ModuleGuard} from '../../../../core/auth';
// Core => Services
import {ClientsService} from '../../../../core/ek-e-commerce/ek-services/clients.service';


// Core => Utils
import {HttpUtilsService, InterceptService, LayoutUtilsService, TypesUtilsService} from '../../../../core/_base/crud';
// Shared
import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../../partials/content/crud';
// Components
// Products
// Orders
// Material
import {
    MAT_DATE_LOCALE,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
} from '@angular/material';
import {NgbDatepickerModule, NgbDropdownModule, NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPermissionsGuard, NgxPermissionsModule} from 'ngx-permissions';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'

import {GalleryModule} from '@ngx-gallery/core';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GallerizeModule} from '@ngx-gallery/gallerize';
import {MatDividerModule} from '@angular/material/divider';
import {CdkTableModule} from '@angular/cdk/table';
import {MatTableExporterModule} from 'mat-table-exporter';
import {ClipboardModule} from 'ngx-clipboard';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {ResizableModule} from 'angular-resizable-element';
import {MatChipsModule} from '@angular/material/chips';
// tslint:disable-next-line:class-name
import {AngularEditorModule} from '@kolkov/angular-editor';
import {CoreModule} from '../../../../core/core.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MatListModule} from "@angular/material/list";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {EkECommerceComponent} from "./ek-e-commerce.component";
import {FoldersListComponent} from './folders/folders-list/folders-list.component';
import {foldersReducer} from "../../../../core/ek-e-commerce/ek-reducers/folder.reducers";
import {PointOfSaleListComponent} from './point-of-sale/point-of-sale-list/point-of-sale-list.component';
import {PointOfSaleEditComponent} from './point-of-sale/point-of-sale-edit/point-of-sale-edit.component';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {PointOfSaleEffects} from "../../../../core/ek-e-commerce/ek-effects/point-of-sale.effects";
import {possReducer} from "../../../../core/ek-e-commerce/ek-reducers/point-of-sale.reducers";
import {FoldersEffects} from "../../../../core/ek-e-commerce/ek-effects/folders.effects";
import {ClientsListPopUpComponent} from './folders/clients-list-pop-up/clients-list-pop-up.component';
import {ClientEffects} from "../../../../core/ek-e-commerce/ek-effects/client.effects";
import {clientsReducer} from "../../../../core/ek-e-commerce/ek-reducers/client.reducers";
import {FolderStepsComponent} from './folders/folder-steps/folder-steps.component';
import {FolderStepOneComponent} from './folders/folder-steps/folder-step-one/folder-step-one.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FolderStepTwoComponent} from './folders/folder-steps/folder-step-two/folder-step-two.component';
import {FolderStepThreeComponent} from './folders/folder-steps/folder-step-three/folder-step-three.component';
import {FolderStepFourComponent} from './folders/folder-steps/folder-step-four/folder-step-four.component';
import {FolderStepFiveComponent} from './folders/folder-steps/folder-step-five/folder-step-five.component';
import {EkStepperComponent} from './folders/ek-stepper/ek-stepper.component';
import {OrderItemsComponent} from './folders/order-items/order-items.component';
import {EkProductEffects} from "../../../../core/ek-e-commerce/ek-effects/ekProducts.effects";
import {EkProductsService} from "../../../../core/ek-e-commerce/ek-services/ek-product.service";
import {ekProductsReducer} from "../../../../core/ek-e-commerce/ek-reducers/ekproduct.reducer";
import {FolderStepSixComponent} from './folders/folder-steps/folder-step-six/folder-step-six.component';
import {FolderStepSevenComponent} from './folders/folder-steps/folder-step-seven/folder-step-seven.component';
import {FolderStepEightComponent} from './folders/folder-steps/folder-step-eight/folder-step-eight.component';
import {orderSplitsReducer} from '../../../../core/ek-e-commerce/ek-reducers/orderSplit.reducers';
import {OrderSplitEffects} from "../../../../core/ek-e-commerce/ek-effects/orderSplit.effects";
import {MatExpansionModule} from "@angular/material/expansion";
import {FolderProcessComponent} from './folders/folder-process/folder-process.component';
import {SecondStepComponent} from './folders/second-step/second-step.component';
import {FilesCardComponent} from './reusable/files-card/files-card.component';
import {FourthStepComponent} from './folders/fourth-step/fourth-step.component';
import {
    OrderSplitProductDetailsComponent
} from './folders/order-items/order-split-product-details/order-split-product-details.component';
import {EKFooterComponent} from './ek-home-page/ek-footer/ekfooter.component';
import {EkFooterFilesComponent} from './ek-home-page/ek-footer/ek-footer-files/ek-footer-files.component';
import {EkQuestionAnswerComponent} from "./ek-home-page/ek-footer/ek-question-answer/ek-question-answer.component";
import {
    EkQuestionAnswerEditComponent
} from './ek-home-page/ek-footer/ek-question-answer/ek-question-answer-edit/ek-question-answer-edit.component';
import {EkFooterInformationService} from '../../../../core/ek-e-commerce/ek-services/ek-footer-information.service';
import {EkFooterFileService} from '../../../../core/ek-e-commerce/ek-services/ek-footer-file.service';
import {
    NewAddressPopUpComponent
} from "./folders/folder-steps/folder-step-seven/new-address-pop-up/new-address-pop-up.component";
import {
    SecondStepCheckListComponent
} from './folders/second-step/second-step-check-list/second-step-check-list.component';
import {DocumentResolver} from "./folders/second-step/resolver/document.resolver";
import {
    DocumentDisplayComponent
} from './folders/second-step/second-step-check-list/document-display/document-display.component';
import {
    DocumentCheckListComponent
} from './folders/second-step/second-step-check-list/document-check-list/document-check-list.component';
import {ChecklistService} from "../../../../core/ek-e-commerce/ek-services/checklist.service";
import {
    SecondStepConfirmationPopupComponent
} from './folders/folder-steps/folder-step-two/second-step-confirmation-popup/second-step-confirmation-popup.component';
import {
    FilesSecondErrorPopupComponent
} from './folders/folder-steps/folder-step-two/files-second-error-popup/files-second-error-popup.component';
import {AddCommentComponent} from './folders/folder-steps/folder-step-eight/add-comment/add-comment.component';
import {OrderSplitChangeStateComponent} from './folders/order-split-change-state/order-split-change-state.component';
import {
    OrderSplitProductsComponent
} from './folders/folder-steps/folder-step-one/order-split-products/order-split-products.component';
import {EditCommentComponent} from './folders/folder-steps/folder-step-eight/edit-comment/edit-comment.component';
import {
    CancelingReasonPopUpComponent
} from './folders/folders-list/canceling-reason-pop-up/canceling-reason-pop-up.component';
import {EkCustomersListComponent} from './ek-customers/ek-customers-list/ek-customers-list.component';
import {
    EkCustomerAddressListComponent
} from './ek-customers/ek-customer-address-list/ek-customer-address-list.component';
import {
    EkCustomerAddressEditComponent
} from './ek-customers/ek-customer-address-edit/ek-customer-address-edit.component';
import {EkCustomerEditComponent} from './ek-customers/ek-customer-edit/ek-customer-edit.component';
import {EkCategoryListComponent} from './ek-categories/ek-category-list/ek-category-list.component';
import {EkCategoryEditComponent} from './ek-categories/ek-category-edit/ek-category-edit.component';
import {EkClientEmailComponent} from './ek-customers/ek-client-email/ek-client-email.component';
import {EkProductsListDialogComponent} from './ek-products/products-list-dialog/products-list-dialog.component';
import {
    EkProductsCharacteristicsDialogComponent
} from "./ek-products/products-characteristics-dialog/products-characteristics-dialog.component";
import {EKProducts2ListComponent} from "./ek-products/products2-list/products2-list.component";
import {EkProducts2AddComponent} from "./ek-products/products2-add/products2-add.component";
import {EkProduct2EditComponent} from "./ek-products/product2-edit/product2-edit.component";
import {
    EkConfigurationCharacteristicComponent
} from "./ek-products/config/characteristics/configuration-characteristic/configuration-characteristic.component";
import {
    EkListsCaracteristicsComponent
} from "./ek-products/config/characteristics/lists-caracteristics/lists-caracteristics.component";
import {
    EkSpecificationEdit2Component
} from "./ek-products/config/characteristics/specification-edit2/specification-edit2.component";
import {EkCharacteristicsComponent} from "./ek-products/config/characteristics/characteristics.component";
import {EkCollaboratorComponent} from "./ek-products/config/collaborator/collaborator.component";
import {EkConfiguration2AddComponent} from "./ek-products/config/configuration2-add/configuration2-add.component";
import {EkConfiguration2TabComponent} from "./ek-products/config/configuration2-tab/configuration2-tab.component";
import {EkEditImgComponent} from "./ek-products/config/edit-img/edit-img.component";
import {EkSettingsTabConfigComponent} from "./ek-products/config/settings-tab-config/settings-tab-config.component";
import {EkBrandsEditComponent} from "./ek-products/brands-edit/brands-edit.component";
import {EkClientAddressService} from "../../../../core/ek-e-commerce/ek-services/ek-client-address.service";
import {AnalystListPopupComponent} from './folders/folders-list/analyst-list-popup/analyst-list-popup.component';
import {
    DocumentsMessagesPopupComponent
} from './folders/second-step/documents-messages-popup/documents-messages-popup.component';
import {OrderStatisticsComponent} from './order-statistics/order-statistics.component';
import {OrderStatisticCartComponent} from './order-statistics/order-statistic-cart/order-statistic-cart.component';
import {ImageViewerModule} from '@udhsin/ngx-image-viewer';
import {FolderStateChangeComponent} from './folders/folder-state-change/folder-state-change.component';
import {MonthlyPaymentPipe} from "../../../../core/_base/layout";
import {FolderStateChangeHistoryComponent} from './folder-state-change-history/folder-state-change-history.component';
import {changeHistoryReducer} from "../../../../core/ek-e-commerce/ek-reducers/change-history.reducers";
import {ChangeHistoryEffects} from "../../../../core/ek-e-commerce/ek-effects/change-history.effects";
import {
    FolderStateChangeDetailsComponent
} from './folder-state-change-history/folder-state-change-details/folder-state-change-details.component';
import {FolderStateChangeReducer} from "../../../../core/ek-e-commerce/ek-reducers/FolderStateChange.reducers";
import {FolderStateChangeEffects} from "../../../../core/ek-e-commerce/ek-effects/FolderStateChange.effects";


import {DateAdapter, MAT_DATE_FORMATS, SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {HistorySmsComponent} from './history-sms/history-sms.component';
import {SmsHistoryDetailsComponent} from './sms-history-details/sms-history-details.component';
import {smsHistoryReducer} from "../../../../core/ek-e-commerce/ek-reducers/sms-history.reducers";
import {SmsHistoryEffects} from "../../../../core/ek-e-commerce/ek-effects/sms-history.effects";
import {SmsHistoryDetailsEffects} from "../../../../core/ek-e-commerce/ek-effects/sms-history-details.effects";
import {smsHistoryDetailsReducer} from "../../../../core/ek-e-commerce/ek-reducers/sms-history-details.reducers";
import {
    DownloadFileDialoguComponent
} from './folders/folder-steps/folder-step-two/download-file-dialogu/download-file-dialogu.component';
import {AgencyBankComponent} from './agency-bank/agency-bank-list/agency-bank.component';
import {AgencyBankEditComponent} from './agency-bank/agency-bank-edit/agency-bank-edit.component';
import {AgencyBankService} from "../../../../core/ek-e-commerce/ek-services/agency-bank.service";
import {AgencyBankEffects} from "../../../../core/ek-e-commerce/ek-effects/agency-bank.effects";
import {agencyBankReducer} from "../../../../core/ek-e-commerce/ek-reducers/agency-bank.reducer";
import {AgenciesBankPopupComponent} from './folders/folders-list/agencies-bank-popup/agencies-bank-popup.component';
import {BankerListPopupComponent} from './agency-bank/banker-list-popup/banker-list-popup.component';
import {VerifyOcrDialogComponent} from './folders/verify-ocr-dialog/verify-ocr-dialog.component';
import {NotificationSettingsComponent} from './notification-settings/notification-settings.component';
import {
    NotificationManagementByRoleComponent
} from './notification-settings/notification-management-by-role/notification-management-by-role.component';
import {NotificationRoleEffects} from "../../../../core/ek-e-commerce/ek-effects/notificationRole.effects";
import {notificationRolesReducer} from "../../../../core/ek-e-commerce/ek-reducers/notificationManagement.reducer";
import {CommentsPopUpComponent} from './folders/comments-pop-up/comments-pop-up.component';
import {MatBadgeModule} from "@angular/material/badge";
import {ConfirmationDialogComponent} from './folders/confirmation-dialog/confirmation-dialog.component';
import {
    PhoneNumberValidationPopupComponent
} from './folders/phone-number-validation-popup/phone-number-validation-popup.component';
import {NewRegionComponent} from './region-management/new-region/new-region.component';
import {PosListPopUpComponent} from './region-management/pos-list-pop-up/pos-list-pop-up.component';
import {RegionListComponent} from './region-management/region-list/region-list.component';
import {WilayaListPopUpComponent} from './region-management/wilaya-list-pop-up/wilaya-list-pop-up.component';
import { AgencyListPopUpComponent } from './region-management/agency-list-pop-up/agency-list-pop-up.component';
import { RatingStepComponent } from './folders/folder-steps/rating-step/rating-step.component';
import { CircularSliderComponent } from './folders/folder-steps/rating-step/circular-slider/circular-slider.component';
import { RatingDetailsComponent } from './folders/folder-steps/rating-step/rating-details/rating-details.component';
import { ScoringThresholdComponent } from './scoring-threshold/scoring-threshold.component';
import { DiscountHistoryComponent } from './folders/folder-steps/folder-step-one/discount-history/discount-history.component';

const routes: Routes = [
    {
        path: '',
        component: EkECommerceComponent,
        children: [
            {
                path: '',
                redirectTo: 'folders',
                pathMatch: 'full',
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'CREATE_FOLDER', 'ONLY_READ'],
                        redirectTo: '/error/403',
                    },
                    title: 'Dossiers'
                }
            },
            // ek Order
            {
                path: 'folders',
                component: FoldersListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'CREATE_FOLDER', 'ONLY_READ'],
                        redirectTo: '/error/403',
                    },
                    title: 'Dossiers'
                }
            },

            //add new folder
            {
                path: 'folder/add/:idClient',
                component: FolderStepsComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'CREATE_FOLDER'],
                        redirectTo: '/error/403',
                    },
                    title: 'Ajouter Dossier'
                }
            },


            //edit folder
            {
                path: 'folder/edit/:idOrder/:idFolder',
                component: FolderStepsComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'CREATE_FOLDER', 'READ_ORDERSPLIT'],
                        redirectTo: '/error/403',
                    },
                    title: 'Dossiers N°'
                }
            },
            {
                path: 'folder/edit/:idOrder/:idFolder/checklist/:idDoc',
                component: SecondStepCheckListComponent,
                resolve: {payload: DocumentResolver},
                // canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'READ_CHECKLIST'],
                        redirectTo: '/error/403',
                    },
                    title: 'Checklist'
                }
            },

            //pos management
            {
                path: 'point-of-sale',
                component: PointOfSaleListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'ALL_POINTRELAIT', 'ONLY_READ'],
                        redirectTo: '/error/403',
                    },
                    title: 'Point de vente'
                }
            },
            {
                path: 'point-of-sale/add',
                component: PointOfSaleEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER'],
                        redirectTo: '/error/403',
                    },
                    title: 'Ajouter un Point de vente'
                }
            },
            {
                path: 'point-of-sale/edit',
                component: PointOfSaleEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'READ_POINTRELAIT'],
                        redirectTo: '/error/403',
                    },
                    title: 'Modifier un Point de vente'
                }
            },
            {
                path: 'point-of-sale/edit/:id',
                component: PointOfSaleEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'ALL_POINTRELAIT'],
                        redirectTo: '/error/403',
                    },
                    title: 'Modifier un Point de vente'
                }
            },

            //home management
            {
                path: 'ek-home-page/ek-footer',
                component: EKFooterComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_FOLDER', 'READ_FOLDER', 'ALL_HOME', 'READ_HOME', 'ONLY_READ'],
                        redirectTo: '/error/403',
                    },
                    title: 'EK Footer'
                }
            },
            {
                path: 'ek-home-page/ek-footer/ek-question-answer',
                component: EkQuestionAnswerComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'READ_QUESTION'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'ek-home-page/ek-footer/ek-question-answer/add',
                component: EkQuestionAnswerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'READ_QUESTION'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'ek-home-page/ek-footer/ek-question-answer/edit',
                component: EkQuestionAnswerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'READ_QUESTION'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'ek-home-page/ek-footer/ek-question-answer/edit/:id',
                component: EkQuestionAnswerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'ONLY_READ', 'READ_QUESTION'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'ek-home-page/ek-footer/:footerFileType',
                component: EkFooterFilesComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'ONLY_READ'],
                        redirectTo: '/error/403'
                    },
                    title: 'Footer -  '
                }
            },
            //ek customer management
            {
                path: 'customers',
                component: EkCustomersListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'READ_CLIENT', 'ONLY_READ'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'addOrder/customers',
                component: EkCustomersListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'READ_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'customers/add',
                component: EkCustomerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'CREATE_CLIENT', 'UPDATE_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'addOrder/customers/add',
                component: EkCustomerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'CREATE_CLIENT', 'UPDATE_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'customers/edit',
                component: EkCustomerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'READ_CLIENT', 'CREATE_CLIENT', 'UPDATE_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'customers/edit/:id',
                component: EkCustomerEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'READ_CLIENT', 'CREATE_CLIENT', 'UPDATE_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            //  Products management
            {
                path: 'products',
                component: EKProducts2ListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_PRODUCT', 'READ_PRODUCT', 'CREATE_PRODUCT', 'UPDATE_PRODUCT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'products2/add',
                component: EkProducts2AddComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_PRODUCT', 'CREATE_PRODUCT', 'UPDATE_PRODUCT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'products2/edit/:id',
                component: EkProducts2AddComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_PRODUCT', 'READ_PRODUCT', 'CREATE_PRODUCT', 'UPDATE_PRODUCT'],
                        redirectTo: '/error/403'
                    }
                }
            },

            {
                path: 'configuration2/add',
                component: EkConfiguration2AddComponent,
                data: {
                    permissions: {
                        only: ['ALL_PRODUCT', 'CREATE_PRODUCT', 'UPDATE_PRODUCT'],
                        redirectTo: '/error/403'
                    }
                }
            },

            //category management
            {
                path: 'categories',
                component: EkCategoryListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CATEGORY', 'READ_CATEGORY', 'ONLY_READ'],
                        redirectTo: '/error/403'
                    }
                }

            },

            //comments management
            {
                path: 'folder/edit/:idOrder/:idFolder/comments',
                component: FolderStepEightComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['UPDATE_COMMENTFOLDER', 'READ_COMMENTFOLDER', 'ONLY_READ'],
                        redirectTo: '/error/403',
                    },
                    title: 'Commentaires'
                }
            },

            //changeHistory management
            {
                path: 'changeHistory',
                component: FolderStateChangeHistoryComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['CREATE_HISTORYSTATEFOLDER', 'ONLY_READ'],
                        redirectTo: '/error/403',
                    },
                    title: 'Historique des modifications'
                }
            },

            //history sms
            {
                path: 'smsHistory',
                component: HistorySmsComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'ONLY_READ'],
                        redirectTo: '/error/403',
                    },
                    title: 'Historique des SMS'
                }
            },
            // Agency Bank
            {
                path: 'agencyBank',
                component: AgencyBankComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'ONLY_READ','ALL_AGENCYBANK'],
                        redirectTo: '/error/403',
                    },
                    title: 'Agence Banque'
                }
            },
            {
                path: 'agencyBank/edit/:id',
                component: AgencyBankEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_AGENCYBANK','ALL_AGENCYBANK'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'agencyBank/add',
                component: AgencyBankEditComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME','ALL_AGENCYBANK'],
                        redirectTo: '/error/403'
                    }
                }
            },
            // settings Notification
            {
                path: 'settingsNotification',
                component: NotificationSettingsComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME'],
                        redirectTo: '/error/403',
                    },
                    title: 'Configuration des notifications'
                }
            },
            // region management
            {
                path: 'regions',
                component: RegionListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_REGION'],
                        redirectTo: '/error/403',
                    },
                    title: 'Regions'
                }
            },
            // rating Threshold management
            {
                path: 'ratingThreshold',
                component: ScoringThresholdComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_REGION'],
                        redirectTo: '/error/403',
                    },
                    title: 'Seuil de Rating'
                }
            },
        ]
    }
];

// @ts-ignore
@NgModule({
    imports: [
        MatInputModule,
        MatSortModule,
        MatTableModule,
        AutocompleteLibModule,
        GalleryModule,
        LightboxModule,
        GallerizeModule,
        MatSlideToggleModule,
        DragDropModule,
        CoreModule,
        ResizableModule,
        CommonModule,
        AngularEditorModule,
        PartialsModule,
        HttpClientModule,
        NgxPermissionsModule.forChild(),
        RouterModule.forChild(routes),
        NgbProgressbarModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatChipsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatDividerModule,
        CdkTableModule,
        MatTableExporterModule,
        NgbDatepickerModule,
        ClipboardModule,
        ResizableModule,
        PerfectScrollbarModule,
        NgbDropdownModule,
        MatListModule,
        MatButtonToggleModule,
        MatDialogModule,
        FlexLayoutModule,
        MatFormFieldModule,
        SatDatepickerModule,
        SatNativeDateModule,

        //folder
        StoreModule.forFeature('folders', foldersReducer),
        EffectsModule.forFeature([FoldersEffects]),

        //point of sale
        StoreModule.forFeature('pos', possReducer),
        EffectsModule.forFeature([PointOfSaleEffects]),

        //clients
        StoreModule.forFeature('clients', clientsReducer),
        EffectsModule.forFeature([ClientEffects]),

        //products
        StoreModule.forFeature('ek-products', ekProductsReducer),
        EffectsModule.forFeature([EkProductEffects]),
        MatExpansionModule,

        //orderSplit
        StoreModule.forFeature('order-splits', orderSplitsReducer),
        EffectsModule.forFeature([OrderSplitEffects]),

        //folder state change history
        StoreModule.forFeature('change-history', changeHistoryReducer),
        EffectsModule.forFeature([ChangeHistoryEffects]),

        //notification Roles management
        StoreModule.forFeature('notification-management', notificationRolesReducer),
        EffectsModule.forFeature([NotificationRoleEffects]),

        //folder states changes
        StoreModule.forFeature('folder-state-change', FolderStateChangeReducer),
        EffectsModule.forFeature([FolderStateChangeEffects]),

        //sms history
        StoreModule.forFeature('history-sms', smsHistoryReducer),
        EffectsModule.forFeature([SmsHistoryEffects]),
        //sms history details
        StoreModule.forFeature('history-sms-details', smsHistoryDetailsReducer),
        EffectsModule.forFeature([SmsHistoryDetailsEffects]),

        //agency bank
        StoreModule.forFeature('agency-bank', agencyBankReducer),
        EffectsModule.forFeature([AgencyBankEffects]),

        CommonModule,
        CommonModule,
        CommonModule,
        ImageViewerModule,
        FormsModule,
        MatBadgeModule,
    ],
    providers: [
        ModuleGuard,
        InterceptService,
        DatePipe,
        MonthlyPaymentPipe,
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px'
            }
        },
        TypesUtilsService,
        LayoutUtilsService,
        HttpUtilsService,
        ClientsService,
        EkProductsService,
        TypesUtilsService,
        LayoutUtilsService,
        EkFooterFileService,
        EkFooterInformationService,
        ChecklistService,
        EkClientAddressService,
        AgencyBankService
    ],
    entryComponents: [
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
    ],

    declarations: [
        EkECommerceComponent,
        SecondStepConfirmationPopupComponent,
        FilesSecondErrorPopupComponent,
        FoldersListComponent,
        PointOfSaleListComponent,
        PointOfSaleEditComponent,
        ClientsListPopUpComponent,
        FolderStepsComponent,
        FolderStepOneComponent,
        FolderStepTwoComponent,
        FolderStepThreeComponent,
        FolderStepFourComponent,
        FolderStepFiveComponent,
        EkStepperComponent,
        OrderItemsComponent,
        FolderStepSixComponent,
        FolderStepSevenComponent,
        FolderStepEightComponent,
        FolderProcessComponent,
        SecondStepComponent,
        FilesCardComponent,
        FourthStepComponent,
        OrderSplitProductDetailsComponent,
        EKFooterComponent,
        EkFooterFilesComponent,
        EkQuestionAnswerComponent,
        EkQuestionAnswerEditComponent,
        NewAddressPopUpComponent,
        SecondStepCheckListComponent,
        DocumentDisplayComponent,
        DocumentCheckListComponent,
        AddCommentComponent,
        OrderSplitChangeStateComponent,
        EditCommentComponent,
        OrderSplitProductsComponent,
        CancelingReasonPopUpComponent,
        EkCustomersListComponent,
        EkCustomerAddressListComponent,
        EkCustomerAddressEditComponent,
        EkCustomerEditComponent,
        EkCategoryListComponent,
        EkCategoryEditComponent,
        EkClientEmailComponent,
        EkProductsListDialogComponent,
        EkProductsCharacteristicsDialogComponent,
        EKProducts2ListComponent,
        EkProducts2AddComponent,
        EkProduct2EditComponent,
        EkConfigurationCharacteristicComponent,
        EkListsCaracteristicsComponent,
        EkSpecificationEdit2Component,
        EkCharacteristicsComponent,
        EkCollaboratorComponent,
        EkConfiguration2AddComponent,
        EkConfiguration2TabComponent,
        EkEditImgComponent,
        EkSettingsTabConfigComponent,
        EkBrandsEditComponent,
        AnalystListPopupComponent,
        DocumentsMessagesPopupComponent,
        OrderStatisticsComponent,
        OrderStatisticCartComponent,
        FolderStateChangeComponent,
        FolderStateChangeHistoryComponent,
        FolderStateChangeDetailsComponent,
        HistorySmsComponent,
        SmsHistoryDetailsComponent,
        DownloadFileDialoguComponent,
        AgencyBankComponent,
        AgencyBankEditComponent,
        AgenciesBankPopupComponent,
        BankerListPopupComponent,
        VerifyOcrDialogComponent,
        NotificationSettingsComponent,
        NotificationManagementByRoleComponent,
        CommentsPopUpComponent,
        ConfirmationDialogComponent,
        PhoneNumberValidationPopupComponent,
        NewRegionComponent,
        PosListPopUpComponent,
        RegionListComponent,
        WilayaListPopUpComponent,
        AgencyListPopUpComponent,
        RatingStepComponent,
        CircularSliderComponent,
        RatingDetailsComponent,
        ScoringThresholdComponent,
        DiscountHistoryComponent,
    ]
})
export class EkECommerceModule {
}
